import { trigger, state, style, transition, animate, query } from '@angular/animations';

export const viewTransition = trigger('viewTransition', [
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 })],
      { optional: true }
    ),
    query(
      ':leave',
       [
           style({
               opacity: 1,
               position: 'absolute',
               width: '100%'
          }),
          animate('250ms ease-in-out', style({
              opacity: 0,
          }))
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
          style({
              opacity: 0,
              position: 'absolute',
              width: '100%'
          }),
          animate('250ms ease-in-out', style({
              opacity: 1,
          }))
      ],
      { optional: true }
    )
  ])
]);

export const homeMobile = trigger('homeMobile', [
  transition(':enter', [
    style({ top: '100vh' }),
    animate('500ms 500ms ease', style({ top: '50px' }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease-in-out', style({ opacity: 0 }))
  ])
]);

export const homeDesktop = trigger('homeDesktop', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms 500ms ease', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease-in-out', style({ opacity: 0 }))
  ])
]);

export const adminPageTransition = trigger('adminPageTransition', [
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 })],
      { optional: true }
    ),
    query(
      ':leave',
       [
           style({
               opacity: 1,
               position: 'absolute',
               width: 'calc(100vw - 350px)'
          }),
          animate('250ms ease-in-out', style({
              opacity: 0,
          }))
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
          style({
              opacity: 0,
              position: 'absolute',
              width: 'calc(100vw - 350px)'
          }),
          animate('250ms ease-in-out', style({
              opacity: 1,
          }))
      ],
      { optional: true }
    )
  ])
]);

export const sideMenu = trigger('sideMenu', [
  transition(':enter', [
    style({ position: 'absolute', opacity: 0, left: '-340px', top: '86px' }),
    animate('250ms ease-in-out', style({ opacity: 1, left: '0' }))
  ]),
  transition(':leave', [
    style({ position: 'absolute', opacity: 1, left: '0', top: '86px' }),
    animate('250ms ease-in-out', style({ opacity: 0, left: '-340px' }))
  ])
]);

export const fade = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('250ms ease', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease', style({ opacity: 0 }))
  ])
]);

export const contentAdmin = trigger('contentAdmin', [
  state('open', style({
    marginLeft: '340px'
  })),
  state('closed', style({
    marginLeft: '10px'
  })),
  transition('open => closed', [
    animate('250ms ease-in-out')
  ]),
  transition('closed => open', [
    animate('250ms ease-in-out')
  ]),
]);

export const loginLogo = trigger('loginLogo', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms 500ms ease-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease', style({ opacity: 0 }))
  ])
]);