import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { adminPageTransition, contentAdmin, sideMenu, viewTransition } from '../animations';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [viewTransition, sideMenu, contentAdmin]
})
export class AdminComponent {

  menuOpened: boolean = true;

  constructor(
    private contexts: ChildrenOutletContexts
  ) {}

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  menu(event: boolean) {
    this.menuOpened = event;
  }

}
