<div class="content">
    <div class="profile">
        <i class="pi pi-user"></i>
        <h3>Dr. Fernando Cárdenas<br><small>Odontólogo</small></h3>
    </div>
    <ul>
        <li>
            <span>Dashboard</span>
            <ul>
                <li [routerLink]="'dashboard'"><i class="pi pi-th-large"></i>Ver dashboard</li>
            </ul>
        </li>
        <li>
            <span>Pacientes</span>
            <ul>
                <li [routerLink]="'patients'"><i class="pi pi-users"></i>Lista de pacientes</li>
                <li [routerLink]="'patients/new'"><i class="pi pi-user-plus"></i>Nuevo paciente</li>
            </ul>
        </li>
        <li>
            <span>Agenda</span>
            <ul>
                <li [routerLink]="'schedule'"><i class="pi pi-calendar"></i>Ver agenda</li>
            </ul>
        </li>
        <li>
            <span>Gestión</span>
            <ul>
                <li [routerLink]="'management/clinic'"><i class="pi pi-building"></i>Clínica</li>
                <li [routerLink]="'management'"><i class="pi pi-chart-line"></i>Finanzas</li>
                <li [routerLink]="'management/treatments'"><i class="pi pi-briefcase"></i>Tratamientos</li>
                <li [routerLink]="'management/inventory'"><i class="pi pi-box"></i>Inventario</li>
                <li [routerLink]="'management/staff'"><i class="pi pi-user"></i>Personal</li>
                <li [routerLink]="'management/schedules'"><i class="pi pi-calendar"></i>Agendas</li>
                <li [routerLink]="'management/scanner'"><i class="pi pi-qrcode"></i>Demo Escáner</li>
            </ul>
        </li>
        <li>
            <span>Configuración</span>
            <ul>
                <li [routerLink]="'settings/import'"><i class="pi pi-upload"></i>Importar datos</li>
            </ul>
        </li>
        <li>
            <ul>
                <li (click)="signOut()"><i class="pi pi-sign-out"></i>Cerrar sesión</li>
            </ul>
        </li>
    </ul>
    <div class="copyright">© 2022 odontapp - {{ version }}</div>
</div>