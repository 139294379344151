// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'piramidentapp',
    appId: '1:5331134812:web:6652ee5a174dd49c363999',
    storageBucket: 'piramidentapp.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAWD4TcxeNBBYk-rppg-01VEZSaxq42ulM',
    authDomain: 'piramidentapp.firebaseapp.com',
    messagingSenderId: '5331134812',
    measurementId: 'G-CE4MJJ3ETW',
  },
  aws: {
    accessKeyId: 'AKIAWJGZTTJA2HKWEAOU',
    secretAccessKey: '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',
    bucket: 'data.togedapp.com',
    bucketUrl: 'https://data.togedapp.com/'
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
