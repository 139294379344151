import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-admin-sidemenu',
  templateUrl: './admin-sidemenu.component.html',
  styleUrls: ['./admin-sidemenu.component.scss']
})
export class AdminSidemenuComponent implements OnInit {

  public version: string = '';

  constructor(
    private auth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.version = packageJson.version;
  }

  signOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['login']);
    });
  }

}
