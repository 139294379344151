<div class="login-container">
    <div class="header-gradient"></div>
    <div class="content">
        <div class="logo" *ngIf="!loading.document && !userNotRegistered" @loginLogo>odontapp<span>{{production ? 'beta' : 'dev'}}</span></div>
        <div class="login-content" *ngIf="!loading.document" @fade>
            <div *ngIf="!clientVerified && !userNotRegistered && !userNotVerified" class="p-fluid">
                <h1>Hola.</h1>
                <p style="margin-top: 0;">Para iniciar sesión en tu clínica, introduce los siguientes datos.
                </p>
                <form [formGroup]="clientForm" (ngSubmit)="clientForm.valid ? verifyClient() : null">
                    <div class="formgrid grid mt-2">
                        <div class="field col-12">
                            <input type="text" placeholder="ID cliente" pInputText formControlName="client" autofocus/>
                        </div>
                        <div class="field col-12">
                            <button pButton type="submit" label="Verificar" [disabled]="clientForm.invalid || loading.form" [icon]="loading.form ? 'pi pi-spin pi-spinner' : ''"></button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="clientVerified && !userNotRegistered && !userNotVerified" class="p-fluid">
                <h1>Hola.</h1>
                <p style="margin-top: 0;">Introduce tus credenciales a continuación para entrar.
                </p>
                <form [formGroup]="signinForm" (ngSubmit)="signinForm.valid ? verifyUser() : null">
                    <div class="formgrid grid mt-2">
                        <div class="field col-12">
                            <span class="p-input-icon-right">
                                <i class="pi pi-user"></i>
                                <input type="text" placeholder="Usuario" pInputText formControlName="user" autofocus/>
                            </span>
                        </div>
                        <div class="field col-12">
                            <p-password formControlName="pass" placeholder="Contraseña" [feedback]="false" [toggleMask]="true"></p-password>
                        </div>
                        <div class="field col-12">
                            <button pButton type="submit" label="Iniciar sesión" [disabled]="signinForm.invalid || loading.form" [icon]="loading.form ? 'pi pi-spin pi-spinner' : ''"></button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="clientVerified && userNotRegistered && !userNotVerified" class="p-fluid">
                <p style="margin-top: 0;">Parece que tu contraseña aún no ha sido registrada. Verifica la contraseña para registrarla. Recibirás un correo electrónico con un enlace para verificar tu cuenta.
                </p>
                <form [formGroup]="signupForm" (ngSubmit)="signupForm.valid ? registerUser() : null">
                    <div class="formgrid grid mt-2">
                        <div class="field col-12">
                            <span class="p-input-icon-right">
                                <i class="pi pi-user"></i>
                                <input type="text" placeholder="Usuario" pInputText [ngModel]="signinForm.controls['user'].value" [ngModelOptions]="{standalone: true}" disabled/>
                            </span>
                        </div>
                        <div class="field col-12">
                            <p-password formControlName="pass" placeholder="Contraseña" [feedback]="true" [toggleMask]="true"></p-password>
                        </div>
                        <div class="field col-12">
                            <p-password formControlName="repeatedPass" placeholder="Repite contraseña" [feedback]="false" [toggleMask]="true"></p-password>
                        </div>
                        <div class="field col-12">
                            <button pButton type="submit" label="Registrarse" [disabled]="signupForm.invalid || loading.form" [icon]="loading.form ? 'pi pi-spin pi-spinner' : ''"></button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="clientVerified && !userNotRegistered && userNotVerified" class="p-fluid">
                <p style="margin-top: 0;">Necesitamos verificar tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada y verifica tu correo electrónico para poder iniciar sesión con tu cuenta.
                </p>
                <form (ngSubmit)="returnToLogin()">
                    <div class="formgrid grid mt-2">
                        <div class="field col-12">
                            <button pButton type="submit" label="Volver a iniciar sesión"></button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- TO DO: Selección de clínica en caso de que el cliente tenga más de una -->
        </div>
    </div>
</div>
