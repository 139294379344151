import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { TabletComponent } from './tablet/tablet.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToAdmin = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'tablet',
    component: TabletComponent,
    loadChildren: () => import('./tablet/tablet.module').then( m => m.TabletModule),
  },
  { 
    path: 'login/:client',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToAdmin,
      animation: 'login'
    }
  },
  { 
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToAdmin,
      animation: 'login'
    }
  },
  { 
    path: '',
    component: AdminComponent,
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      animation: 'admin'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
