import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, ChildrenOutletContexts, Route, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StorageService } from './_services/storage.service';
import { viewTransition } from './animations';
import { Client } from './_models/clients';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [viewTransition]
})
export class AppComponent implements OnInit {

  public title = 'odontapp';

  constructor(
    private contexts: ChildrenOutletContexts,
    private router: Router,
    private storageService: StorageService,
    private messageService: MessageService,
    private readonly afs: AngularFirestore,
    private readonly afa: AngularFireAuth
  ) { }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  ngOnInit(): void {
    this.afa.authState.subscribe((user) => {
      console.log('AUTH:', user);
      if (user) {
        if (this.storageService.getClient) {
          const clientCode = this.storageService.getClient;
          this.afs.collection('clients').doc<Client>(clientCode)
          .collection('staff', ref => ref.where('contactInfo.email', '==', user.email))
          .snapshotChanges(['added', 'modified', 'removed']).subscribe((data) => {
            if (data.length === 1) {
              if (data[0].type === 'removed') {
                this.signOut();
                this.messageService.add({
                  key: 'global',
                  severity: 'error',
                  summary: 'Acceso denegado',
                  detail: 'El usuario con el que estaba conectado ya no tiene acceso. Por favor, contacte con el administrador.',
                  life: 10000
                });
              }
            } else {
              this.signOut();
            }
          });
        } else {
          this.signOut();
        }
      } else {
        this.ereaseStorage();
      }
    });
  }

  private signOut(): void {
    this.afa.signOut().then(() => {
      this.ereaseStorage();
      this.router.navigate(['']);
    });
  }

  private ereaseStorage(): void {
    this.storageService.ereaseClient();
    this.storageService.ereaseClinic();
  }
}
