import { Component, OnInit } from '@angular/core';
import { adminPageTransition } from '../animations';

@Component({
  selector: 'app-tablet',
  templateUrl: './tablet.component.html',
  styleUrls: ['./tablet.component.scss'],
  animations: [adminPageTransition]
})
export class TabletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
