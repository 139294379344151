import { Injectable } from '@angular/core';
import { Staff } from '../_models/staff';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveClient(client: string) {
    sessionStorage.setItem('client', client);
  }

  saveClinic(clinic: string) {
    sessionStorage.setItem('clinic', clinic);
  }

  get getClient(): string | undefined {
    const client = sessionStorage.getItem('client');
    return client || undefined;
  }

  get getClinic(): string | undefined {
    const clinic = sessionStorage.getItem('clinic');
    return clinic || undefined;
  }

  ereaseClient() {
    sessionStorage.removeItem('client');
  }

  ereaseClinic() {
    sessionStorage.removeItem('clinic');
  }
}
