import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  menuIsOpened: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.menuToggle.emit(this.menuIsOpened);
  }

  toggleMenu() {
    this.menuIsOpened = !this.menuIsOpened;
    this.menuToggle.emit(this.menuIsOpened);
  }
}
