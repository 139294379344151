import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/compat/app';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { loginLogo, fade } from '../animations';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Client } from '../_models/clients';
import { Clinic } from '../_models/clinics';
import { Staff } from '../_models/staff';
import { StorageService } from '../_services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [loginLogo, fade]
})
export class LoginComponent implements OnInit {

  public loading = {
    document: true,
    form: false
  };

  public clientVerified: boolean = false;

  public userNotRegistered: boolean = false;
  public userNotVerified: boolean = false;

  public clientForm!: FormGroup;
  public signinForm!: FormGroup;
  public signupForm!: FormGroup;

  public production: boolean = environment.production;

  private clientCollection: AngularFirestoreCollection<Client>;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private readonly afs: AngularFirestore,
    private readonly afa: AngularFireAuth
  ) {
    this.clientCollection = afs.collection<Client>('clients');
    this.activatedRoute.params.subscribe((params) => {
      if (params['client']) {
        setTimeout(() => {
          this.clientForm.get('client')?.setValue(params['client']);
          this.verifyClient();
        });
      } else {
        setTimeout(() => {
          this.loading.document = false;
        }, 100);
      }
    });
  }

  ngOnInit(): void {
    this.clientForm = this.fb.group({
      client: ['', Validators.required]
    });
    this.signinForm = this.fb.group({
      user: ['', Validators.required],
      pass: ['', Validators.required]
    });
    this.signupForm = this.fb.group({
      pass: ['', Validators.required],
      repeatedPass: ['', Validators.required]
    });
  }

  /**
   * Verifica si existe el cliente.
   */
  verifyClient(): void {
    if (this.clientForm.valid){
      this.loading.form = true;
      this.clientForm.get('client')?.disable();
      const client = this.clientForm.get('client')?.value;
      this.clientCollection.doc(client).get().subscribe({
        next: (snapshot) => {
          this.loading.document = false;
          this.loading.form = false;
          if (snapshot.exists) {
            this.clientVerified = true;
          } else {
            this.clientForm.get('client')?.enable();
            this.messageService.add({
              key: 'global',
              severity: 'error',
              summary: 'Cliente inexistente',
              detail: 'El cliente indicado no existe.'
            });
          }
        }
      });
    }
  }

  /**
   * Verifica el usuario y la contraseña.
   * También verifica si el usuario está registrado en el sistema y si está verificado.
   */
  verifyUser() {
    if (this.signinForm.valid) {
      this.loading.form = true;
      this.signinForm.get('user')?.disable();
      this.signinForm.get('pass')?.disable();
      const client = this.clientForm.get('client')?.value;
      const user = this.signinForm.get('user')?.value;
      const pass = this.signinForm.get('pass')?.value;
      this.clientCollection.doc(client).collection('staff', ref => ref.where('personalInfo.documentID', '==', user))
      .get().subscribe({
        next: (snapshots) => {
          if (!snapshots.empty && snapshots.size === 1) {
            snapshots.forEach((snapshot) => {
              const data = snapshot.data() as Staff;
              console.log(data);
              this.afa.signInWithEmailAndPassword(data.contactInfo.email, pass).then((userCredential) => {
                console.log(userCredential)
                if (!userCredential.user?.emailVerified) {
                  this.userNotVerified = true;
                  this.userNotRegistered = false;
                  this.sendVerificationEmail();
                  this.afa.signOut();
                  this.loading.form = false;
                } else {
                  this.storageService.saveClient(client);
                  // TEMPORAL: Se guarda la primera clínica del usuario.
                  // TO DO: Seleccionar la clínica a la que se quiere ingresar.
                  this.storageService.saveClinic(data.clinics[0].id);
                  this.router.navigate(['/dashboard']);
                }
              })
              .catch((error) => {
                if (error.code === 'auth/user-not-found') {
                  this.userNotRegistered = true;
                  this.signupForm.get('pass')?.setValue(pass);
                } else  if (error.code === 'auth/wrong-password') {
                  this.messageService.add({
                    key: 'global',
                    severity: 'error',
                    summary: 'Error de inicio de sesión',
                    detail: 'El usuario o la contraseña es incorrecta.'
                  });
                } else if (error.code === 'auth/user-disabled') {
                  this.messageService.add({
                    key: 'global',
                    severity: 'error',
                    summary: 'Error de inicio de sesión',
                    detail: 'El usuario está deshabilitado.'
                  });
                }
                this.signinForm.get('user')?.enable();
                this.signinForm.get('pass')?.enable();
                this.loading.form = false;
              });
            })
          } else {
            this.messageService.add({
              key: 'global',
              severity: 'error',
              summary: 'Error de inicio de sesión',
              detail: 'El usuario o la contraseña es incorrecta.'
            });
            this.loading.form = false;
          }
        }
      });
    }
  }

  /**
   * Registra el usuario en el sistema y envía un correo de verificación.
   */
  registerUser() {
    if (this.signupForm.valid && this.signupForm.controls['pass'].value === this.signupForm.controls['repeatedPass'].value) {
      this.loading.form = true;
      this.signupForm.get('user')?.disable();
      this.signupForm.get('repeatedPass')?.disable();
      const client = this.clientForm.get('client')?.value;
      const user = this.signinForm.get('user')?.value;
      const pass = this.signupForm.get('pass')?.value;
      this.clientCollection.doc(client).collection('staff', ref => ref.where('personalInfo.documentID', '==', user))
      .get().subscribe({
        next: (snapshots) => {
          const data = snapshots.docs[0].data() as Staff;
          this.afa.createUserWithEmailAndPassword(data.contactInfo.email, pass).then(() => {
            this.userNotRegistered = false;
            this.userNotVerified = true;
            this.sendVerificationEmail();
            this.afa.signOut();
            this.loading.form = false;
          });
        }
      });
    } else {
      this.messageService.add({
        key: 'global',
        severity: 'error',
        summary: 'Error de registro',
        detail: 'Las contraseñas no coinciden.'
      });
      this.loading.form = false;
      this.signupForm.get('user')?.enable();
      this.signupForm.get('repeatedPass')?.enable();
    }
  }

  /**
   * Envía un correo de verificación al usuario.
   */
  sendVerificationEmail() {
    this.afa.currentUser.then((user) => {
      user?.sendEmailVerification().then(() => {
        this.messageService.add({
          key: 'global',
          severity: 'success',
          summary: 'Correo enviado',
          detail: 'Se ha enviado un correo de verificación a su cuenta de correo.'
        });
      });
    });
  }

  /**
   * Vuelve al inicio de sesión.
   */
  returnToLogin() {
    this.userNotRegistered = false;
    this.userNotVerified = false;
    this.signinForm.reset();
  }
}
