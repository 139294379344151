<div class="header">
    <div class="left">
        <!-- <img src="./assets/images/icon.png" pTooltip="Volver a la página principal" [showDelay]="500" routerLink="/"> -->
        <button pButton pRipple type="button" icon="pi pi-bars" pTooltip="Ocultar menú" tooltipPosition="bottom" [showDelay]="500" class="p-button-rounded  p-button-secondary"></button>
        <h2>Clínica Dental Pirámides</h2>
    </div>

    <div class="right">
        <button pButton pRipple type="button" icon="pi pi-eye-slash" pTooltip="Ocultar información" tooltipPosition="bottom" [showDelay]="500" class="userMenuButton p-button-rounded p-button-text"></button>
        <button pButton pRipple type="button" icon="pi pi-bell" pTooltip="Notificaciones" tooltipPosition="bottom" [showDelay]="500" class="userMenuButton p-button-rounded p-button-text"></button>
        <button pButton pRipple type="button" icon="pi pi-lock" pTooltip="Bloquear pantalla" tooltipPosition="bottom" [showDelay]="500" class="userMenuButton p-button-rounded p-button-text"></button>
    </div>
</div>