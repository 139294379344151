import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tablet-header',
  templateUrl: './tablet-header.component.html',
  styleUrls: ['./tablet-header.component.scss']
})
export class TabletHeaderComponent implements OnInit {

  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  menuIsOpened: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.menuToggle.emit(this.menuIsOpened);
  }

  toggleMenu() {
    this.menuIsOpened = !this.menuIsOpened;
    this.menuToggle.emit(this.menuIsOpened);
  }
}
